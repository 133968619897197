import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "core-js/modules/es.array.push.js";
import { projectList } from '@/api/wxproject';
export default {
  components: {
    [_Cell.name]: _Cell,
    [_Empty.name]: _Empty,
    [_Collapse.name]: _Collapse,
    [_CollapseItem.name]: _CollapseItem
  },
  data() {
    return {
      activeNames: [],
      objectlist: []
    };
  },
  mounted() {
    document.title = '历史记录';
    projectList({
      type: 'history'
    }).then(res => {
      this.objectlist = res.data.data;
    });
  },
  methods: {
    goRealtimedynamicDetails(projectCode, month) {
      this.$router.push({
        path: '/RealtimedynamicDetails',
        query: {
          projectCode: projectCode,
          month: month,
          type: 'history'
        }
      });
    }
  }
};